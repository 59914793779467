import type { cs } from "./cs"

export const de: typeof cs = {
    translation: {
        general: {
            bookNow: 'Reservieren',
            bookNowFor: 'Reservieren für',
            czk: 'CZK',
            occupied: 'Besetzt',
            occupiedShort: 'Besetzt',
            min: 'Min.',
            hours: 'Stunden',
            hoursShortcut: 'St.',
            list: 'Liste',
            map: 'Karte',
            filters: 'Filter',
            hide: 'Schließen',
            garagesFound_one: 'Garage <strong>{{count}} gefunden</strong>',
            garagesFound_few: 'Garagen <strong>{{count}} gefuden</strong>',
            garagesFound_many: 'Garagen <strong>{{count}} gefunden</strong>',
            garagesFound_other: 'Garagen <strong>{{count}} gefunden</strong>',
            garagesAvailableLater_one: 'Es scheint, dass viele Garagen zu dieser Zeit ausgebucht sind. Verschieben Sie den Reservierungsbeginn auf <strong>18:00</strong> und es wird <strong>{{count}} weitere Garage verfügbar sein</strong>.',
            garagesAvailableLater_few: 'Es scheint, dass viele Garagen zu dieser Zeit ausgebucht sind. Verschieben Sie den Reservierungsbeginn auf <strong>18:00</strong> und es werden weiteren <strong>{{count}} Garagen verfügbar sein</strong>.',
            garagesAvailableLater_many: 'Es scheint, dass viele Garagen zu dieser Zeit ausgebucht sind. Verschieben Sie den Reservierungsbeginn auf <strong>18:00</strong> und es werden weiteren <strong>{{count}} Garagen verfügbar sein</strong>.',
            garagesAvailableLater_other: 'Es scheint, dass viele Garagen zu dieser Zeit ausgebucht sind. Verschieben Sie den Reservierungsbeginn auf <strong>18:00</strong> und es werden weiteren <strong>{{count}} Garagen verfügbar sein</strong>.',
            moveToEighteen: 'Reservierung auf 18:00 verschieben',
            close: 'Schließen',
            availableFromEighteen: 'Verfügbar ab 18:00',
            fromEighteen: 'Ab 18:00',
            lastPlacesAvailable: 'Letzte Plätze',
            boolean: {
                true: 'Ja',
                false: 'Nein'
            },
            phoneInput: {
                searchPlaceholder: "Eine Vorwahl auswählen",
            }
        },
        filters: {
            showAvailable: 'Nur verfügbare Garagen zeigen',
            yesShow: 'Ja',
            priceRange: 'Preisspanne',
            carHeight: 'Höhe Ihres Fahrzeugs',
            sortBy: 'Sortieren nach',
            distance: 'Entfernung',
            price: 'Preis',
            remove: 'Alle Filter löschen',
            reset: 'Alle Filter löschen',
            showResults: 'Ergebnisse zeigen',
            noResultsMatch: 'Keine Garagen entsprechen diesen Filtern.',
        },
        search: {
            reset: 'Rücksetzen',
            edit: 'Verändern',
            search: 'Suchen',
            where: 'Wo möchten Sie parken?',
            wherePlaceholder: 'Stadt, Straße, Ort',
            whereEmpty: 'Keine Optionen',
            start: 'Von:',
            end: 'Bis:',
            reservationStart: 'Reservierung von',
            reservationEnd: 'Reservierung bis',
            reservationLength: 'Dauer der Reservierung',
            reservationExtendedLength: 'Dauer einer verlängerten Reservierung',            
            reservationDate: 'Reservierungsdatum',
            days_one: ' {{count}} Tag',
            days_few: ' {{count}} Tage',
            days_many: ' {{count}} Tage',
            days_other: ' {{count}} Tage',
            hours_one: ' {{count}} Stunde',
            hours_few: ' {{count}} Stunden',
            hours_many: ' {{count}} Stunden',
            hours_other: ' {{count}} Stunden',
            minutes_one: 'Minute',
            minutes_few: 'Minuten',
            minutes_many: 'Minuten',
            minutes_other: 'Minuten',            
            selectStart: 'Wählen Sie den Beginn der Reservierung',
            selectEnd: 'Wählen Sie das Ende der Reservierung',
            startInThePast: 'Der Beginn Ihrer Reservierung liegt in der Vergangenheit!',
            defaultLocation: 'Parken in der Tschechischen Republik',
            extensionStartsTooLate: 'Die verlängerte Reservierung darf nicht später als die ursprüngliche Reservierung beginnen.',
            extensionEndsTooEarly: 'Die verlängerte Reservierung darf nicht früher als die ursprüngliche Reservierung enden.',
            extensionIntoThePast: 'Die Reservierung kann nicht in die Vergangenheit verlängert werden.',
            checkAvailability: 'Verfügbarkeit prüfen',
            sendExtensionRequest: 'Verlängerungsantrag senden',
            return: 'Zurück',
            extendReservation: 'Reservierung verlängern',
            extensionPossible: 'Es ist möglich, Ihre Reservierung zu verlängern!',
            extensionPrice: 'Preis für die Verlängerung: {{price}} CZK',
            payToContinue: 'Um die Verlängerung zu bestätigen, zahlen Sie bitte den Restbetrag.',
            toPaymentCTA: 'Zur Zahlung',
        },
        routes: {
            garages: 'garagen',
            reservations: 'reservierungen',
            reservations_steps: {
                reservation_time: 'reservierungszeit',
            },
            users: 'benutzer',
            signIn: 'anmeldung',
            register: 'registrierung',
            signOut: 'abmeldung',
        },
        reservations: {
            status: {
                booked: 'Künftig',
                current: 'Aktiv',
                elapsed: 'Abgelaufen',
                cancelled: 'Storniert',
                allocated: 'Zugewiesene',
            },
            general: {
                active: 'Aktive Reservierungen',
                all: 'Alle Reservierungen',
                noBooked: 'Sie haben keine aktiven oder zukünftigen Reservierungen.',
                noBookedCreate: 'Eine Reservierung erstellen',
                remaining: 'verbleibend',
            },
            filters: {
                sortBy: 'Sortieren nach',
                id: 'ID',
                startAt: 'Reservierungsbeginn',
                status: 'Status-Filter',
                all: 'Alle',
                active: 'Aktiv',
                elapsed: 'Abgelaufen',
                cancelled: 'Storniert',
                allocated: 'Zugewiesene',
            },
            table: {
                reservation: 'Reservierung',
                garage: 'Garage',
                unit: 'Platz',
                driver: 'Fahrer',
                licensePlate: 'Kennzeichen',
                phone: 'Handynummer',
                date: 'Datum',
                price: 'Preis',
                createdAt: 'Erstellt am',
                garageAndUnit: 'Garage and Platz',
                from: 'Von',
                to: 'Bis',
                phoneNumbers: 'Telefonnummern',
                cancelReservation: 'Reservierung stornieren',
                editDetails: 'Kennzeichen/Handynummer ändern',
                bookNextDay: 'Am nächsten Tag wiederholen',
                bookAgain: 'Die gleiche Garage buchen',
                unpaidDifference: 'Bitte zahlen Sie {{price}} {{currency}}, um die Verlängerung abzuschließen.',
                changeUnitApprove: 'Um die Stellplatzänderung abzuschließen, bestätigen Sie bitte die Garagenordnung.',
                confirm: 'Bestätigen',
            },
            documents: {
                tickets: 'Tickets',
                invoices: 'Rechnungen',
                invoiceCorrections: 'Korrekturbelege',
                creditNotes: 'Gutschrifte',
                other: 'Andere Dokumente',
                termsOfService: 'Nutzungsbedingungen',
                privacyPolicy: 'Datenschutz',
                garageOperatingTerms: 'Garagenbetriebsordnung',
            },
            active: {
                open: 'Öffnen',
                entrance: 'Einfahrt',
                exit: 'Ausfahrt',
                reception: 'Rezeption',
                extend: 'Verlängern',
                payExtension: 'Zahlen Verlängerung',
                cancel: 'Stornieren',
                confirm: 'Bestätigen',
                ending: 'Endet am',
                starting: 'Beginnt am',
                ended: 'Abgelaufen',
            },
            edit: {
                save: 'Speichern',
                cancel: 'Stornieren',
                phoneNumber1: 'Handynummer 1',
                phoneNumber2: 'Handynummer 2',
                licensePlate: 'Kennzeichen',
            },
            cancel: {
                confirmation: 'Möchten Sie diese Reservierung wirklich stornieren?',
                detail: 'Nach der Stornierung der Reservierung ist es nicht mehr möglich, sie zu erneuern.',
                cancel: 'Reservierung stornieren',
                keep: 'Reservierung behalten',
            },
            empty: {
                title: 'Sie haben noch keine Reservierung vorgenommen',
                text: 'Erstellen Sie eine neue Reservierung. Hier können Sie dann alle Ihre Reservierungen sehen.'
            },
        },
        profile: {
            refetch: 'neu laden',
            languageSelector: 'Sprachauswahl',
            currencySelector: 'Währungsauswahl',
            signOut: 'Abmelden',
            userProfile: {
                title: "Benutzerprofil",
                email: "E-Mail",
                userName: "Vor-und Nachname",
                phoneNumber1: "Handynummer 1",
                phoneNumber1Tooltip: "autorisiert zum Öffnen der Garage",
                phoneNumber2: "Handynummer 2",
                agreedToMarketing: "Zustimmen mit Marketing",
                edit_agreedToMarketing: "Marketing ermöglichen",
            },
            invoiceDetails: {
                title: "Rechnungsdaten",
                edit_title: "Rechnungsdaten ändern",
                edit_save: "Ändern",
                empty: "Noch nicht eingetragen",
                invoice_title: "Firma",
                invoice_street: "Straße",
                invoice_city: "Stadt",
                invoice_zip_code: "PLZ",
                invoice_country: "Land",
                invoice_ic: "W-IdNr",
                invoice_dic: "USt-IdNr",
            },
            cars: {
                title: "Fahrzeuge",
                edit_title: "Fahrzeug ändern",
                edit_save: "Ändern",
                add_title: "Fahrzeug hinzufügen",
                add_confirm: "Hinzufügen",
                name: "Name",
                numberPlate: "KFZ",
                color: "Farbe",
                add: "Hinzufügen"
            }
        },
        success: {
            congratulations: {
                title: 'Erfolgreiche Reservierung" Danke.',
                imageAlt: 'Illustratives Bild der Reservierung',
                text: 'Wir haben einen Parkschein mit den Details Ihrer Reservierung an die angegebene E-Mail-Adresse geschickt. Für Ihren Komfort haben wir auch alles in einer SMS-Nachricht an die von Ihnen angegebenen Telefonnummern gesendet. Der Parkplatz muss spätestens am Ende der Reservierung verlassen werden!',
                myReservationsLink: 'Meine Reservierungen',
            },
            summary: { 
                title: 'Detail der Reservierung',
            },
            reservationSummary: {
                totalPrice: 'Insgesamt',           
                email: 'E-Mail',
                name: 'Vor-und Nachname',
                numberPlate: 'KFZ',
                carColor: 'Farbe',
                floorWithNumber:'Etage/Parkplatznummer',
                phoneNumber:'Handynummer 1',
                phoneNumber2:'Handynummer 2',
            }
        },
        garage: {
            car_dimensions: {
                title: 'Maximale Fahrzeugabmessungen',
                height: 'Höhe',
                length: 'Länge',
                width: 'Breite',
            },
            showLess: 'Weniger anzeigen',
            showMore: 'Mehr anzeigen',
            mainBenefits: {
                first: 'Wiederholte Ein- und Ausfahrt',
                second: 'Anweisung per SMS und E-Mail',
                third: 'Einfache Stornierung und Veränderug der Reservierung',
            },
            openOnMap: "navigieren",
            benefits: {
                title: 'Parameter',
                list: {
                    '10_minutes_form_city_center': 'Zentrum bis 10 min entfernt',
                    '15_minutes_from_city_center': 'Zentrum bis 15 min entfernt',
                    '5_minutes_form_city_center': 'Zentrum bis 5 min entfernt',
                    call_reception: 'Bitte rufen Sie die Rezeption bei der Ankunft an.',
                    cctv: 'Videoüberwachung',
                    cctv_at_entrance: 'Videoüberwachung an der Einfahrt',
                    city_center: 'Stadtzentrum',
                    historic_city_center: 'Altstadt',
                    charging_station: 'Ladestationen für Elektrofahrzeuge',
                    licence_plate_validation: 'Öffnen Sie die Schranke mit Kennzeichenerkennung',
                    limited_vehicle_dimensions: 'Maximale Fahrzeuggrösse',
                    main_reception: 'Der Zugang zur Garage erfolgt über die Hauptrezeption in der Lobby - erst dann können Sie in die Garage einfahren.',
                    nonstop_operation: 'Non-stop Betrieb',
                    nonstop_reception: 'Non-stop Rezeption',
                    nonstop_service: 'Non-stop Betrieb',
                    number_plate_recognition: 'Die Videokamera liest Ihr KfZ-Zeichen und öffnet die Schranke',
                    open_gate_by_attendant: 'Rezeption wird das Tor öffnen',
                    open_gate_by_phone: 'Das Garagentor öffnen Sie per Handy',
                    parking_for_disabled: 'Parken für Behinderte',
                    security_system: 'Sicherheitssystem',
                    subway_availability: 'Metro in der Nähe',
                    tram_availability: 'Straßenbahn in der Nähe',
                    wc: 'WC',
                },
            },
            prohibitions: {
                title: 'Verbote',
                list: {
                    lpg_cng_not_allowed: 'Keine Einfahrt für CNG und LPG',
                    electric_car_not_allowed: 'Parken von Elektroautos verboten',
                    motorbikes_not_allowed: 'Kein Motorrad Parkplatz',
                },
            },
            garage_videos: {
                title: 'Videogalerie',
            },
            confirmationModal: {
                maxDimensionsTitle: 'Achtung',
                cancel: 'Stornieren',
                complete: 'Reservieren',
            }
        },
        loginCrossroads: {
            login: "Anmelden",
            createAccount: "Konto erstellen",
            skip: "Jetzt überspringen",
        },
        pwa: {
            search: 'Suchen',
            myReservations: 'Meine Reservierungen',
            profile: 'Profil',
            myReservationsNoUser: 'Um Reservierungen zu verwalten und Garagen zu öffnen, müssen Sie sich einloggen:',
            profileNoUser: 'Um Ihr Profil, Ihre Fahrzeuge und Ihre Rechnungen zu verwalten, müssen Sie sich einloggen:'
        },
    },
}