import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.js'
import {
    Currency,
    type Reservation,
    ReservationStatuses,
    ReservationStatusesFromAPI,
} from './types'

export function isBeforeDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  
    const aYear = a.year();
    const aMonth = a.month();
  
    const bYear = b.year();
    const bMonth = b.month();
  
    const isSameYear = aYear === bYear;
    const isSameMonth = aMonth === bMonth;
  
    if (isSameYear && isSameMonth) return a.date() < b.date();
    if (isSameYear) return aMonth < bMonth;
    return aYear < bYear;
}

export function isInclusivelyAfterDay(a, b) {
    if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
    return !isBeforeDay(a, b);
}

export function isFriday(date) {
    const dayOfWeek = moment(date).isoWeekday()
    // biome-ignore lint: Potential comparison of number to string
    return dayOfWeek == 5
}

export function getDefaultDateAndTime() {
    moment.tz.setDefault('Europe/Prague')

    const currentTimeInCET = moment()

    let defaultTime = '00:01'
    const defaultDate = moment()

    if (currentTimeInCET.isAfter(moment().set({ hour: 5, minute: 40 }))) {
        defaultTime = '06:00'
    }
    if (currentTimeInCET.isAfter(moment().set({ hour: 11, minute: 40 }))) {
        defaultTime = '12:00'
    }
    if (currentTimeInCET.isAfter(moment().set({ hour: 17, minute: 40 }))) {
        defaultTime = '18:00'
    }
    if (currentTimeInCET.isAfter(moment().set({ hour: 23, minute: 40 }))) {
        defaultTime = '00:01'
        defaultDate.add(1, 'days')
    }

    return {
        defaultDate,
        defaultTime,
    }
}

export function getSearchParams() {
    const {defaultDate, defaultTime} = getDefaultDateAndTime()

    const domNode = document.getElementById('react-garage-detail-search')
    const serviceBeginsAtDate = domNode?.getAttribute('reservation-begins-at-date')
    const serviceBeginsAtTime = domNode?.getAttribute('reservation-begins-at-time')
    const serviceEndsAtDate = domNode?.getAttribute('reservation-ends-at-date')
    const serviceEndsAtTime = domNode?.getAttribute('reservation-ends-at-time')

    const searchParams = new URLSearchParams(document.location.search)

    const variantA = 'map_search'
    const variantB = 'reservation_step_reservation_time'
    
    const paramLocation =     searchParams.get(variantA+'[location]')       || searchParams.get(variantB+'[location]')       || null
    const paramBeginsAtDate = searchParams.get(variantA+'[begins_at_date]') || searchParams.get(variantB+'[begins_at_date]') || serviceBeginsAtDate || defaultDate.format('YYYY-MM-DD')
    const paramBeginsAtTime = searchParams.get(variantA+'[begins_at_time]') || searchParams.get(variantB+'[begins_at_time]') || serviceBeginsAtTime || defaultTime
    const paramEndsAtDate =   searchParams.get(variantA+'[ends_at_date]')   || searchParams.get(variantB+'[ends_at_date]')   || serviceEndsAtDate   || defaultDate.clone().add(1, 'days').format('YYYY-MM-DD')
    const paramEndsAtTime =   searchParams.get(variantA+'[ends_at_time]')   || searchParams.get(variantB+'[ends_at_time]')   || serviceEndsAtTime   || defaultTime

    return {
        paramLocation,
        paramBeginsAtDate,
        paramBeginsAtTime,
        paramEndsAtDate,
        paramEndsAtTime,
    }
}

export function getReservationLength(paramBeginsAtDate, paramBeginsAtTime, paramEndsAtDate, paramEndsAtTime): number {

    const normalisedBeginsAtTime = paramBeginsAtTime === '00:01' ? '00:00' : paramBeginsAtTime
    const normalisedEndsAtTime = paramEndsAtTime === '00:01' ? '00:00' : paramEndsAtTime
    
    const startMoment = moment(paramBeginsAtDate + " " + normalisedBeginsAtTime, ['DD.MM.YYYY HH:mm', 'YYYY-MM-DD HH:mm'])
    const endMoment = moment(paramEndsAtDate + " " + normalisedEndsAtTime, ['DD.MM.YYYY HH:mm', 'YYYY-MM-DD HH:mm'])

    const reservationLengthHours = endMoment.diff(startMoment, 'hours')

    return reservationLengthHours
}

export function getCurrency(): Currency {
    const domNode = document.getElementById('react-garages')
    const currency = domNode?.getAttribute('currency')

    return currency === Currency.eur ? Currency.eur : Currency.czk
}

export function getDayLimits(): {
    maximumStayDays: number,
    maximumDaysInAdvance: number,
} {
    let maximumStayDays = 30
    let maximumDaysInAdvance = 180
    
    const domNodes = [
        document.getElementById('react-garages'),
        document.getElementById('react-garage-detail-search'),
        document.getElementById('react-home-search'),
    ]

    for (const domNode of domNodes) {
        if (domNode) {
            if (domNode?.getAttribute('maximum-stay-days')) {
                //@ts-ignore
                maximumStayDays =  Number.parseInt(domNode.getAttribute('maximum-stay-days'))
            }
            if (domNode?.getAttribute('maximum-days-in-advance')) {
                //@ts-ignore
                maximumDaysInAdvance =  Number.parseInt(domNode.getAttribute('maximum-days-in-advance'))
            }
        }
    }

    return {
        maximumStayDays,
        maximumDaysInAdvance,
    }
}

export function getReservationStatus(state, beginsAt, endsAt) {
    if (state === ReservationStatusesFromAPI.booked && moment() > moment(endsAt)) {
        return ReservationStatuses.elapsed
    }
    if (state === ReservationStatusesFromAPI.booked && moment() < moment(beginsAt)) {
        return ReservationStatuses.booked
    }
    if (state === ReservationStatusesFromAPI.booked && moment() > moment(beginsAt) && moment() < moment(endsAt)) {
        return ReservationStatuses.current
    }
    if (state === ReservationStatusesFromAPI.allocated) {
        return ReservationStatuses.allocated
    }
    return ReservationStatuses.canceled
}

export function getReservationLinkNextDay(reservation: Reservation, path: string): string {
    
    const startDate = moment(reservation.startDate).add(1, 'days').format('YYYY-MM-DD')
    const startTime = moment(reservation.startDate).format('HH:mm').replace('00:00', '00:01')
    const endDate = moment(reservation.endDate).add(1, 'days').format('YYYY-MM-DD')
    const endTime = moment(reservation.endDate).format('HH:mm').replace('00:00', '00:01')

    const reservationLinkNextDay = `/${path
        }?reservation_step_reservation_time%5Bbegins_at_date%5D=${
        startDate}&reservation_step_reservation_time%5Bbegins_at_time%5D=${
        startTime}&reservation_step_reservation_time%5Bends_at_date%5D=${
        endDate}&reservation_step_reservation_time%5Bends_at_time%5D=${
        endTime}&reservation_step_reservation_time%5Bgarage_id%5D=${
        reservation.garageId}`

    return reservationLinkNextDay
}

export function getReservationLinkSameGarage(reservation: Reservation, path: string): string {

    const reservationLinkSameGarage = `/${path
        }?reservation_step_reservation_time%5Bgarage_id%5D=${reservation.garageId}`

    return reservationLinkSameGarage
}

export function mapReservation(reservation): Reservation {
    return ({
        id: reservation.id,
        status: getReservationStatus(reservation.state, reservation.begins_at, reservation.ends_at),
        garageId: reservation.unit?.garage?.id,
        garageName: reservation.unit?.garage?.name,
        garagePlace: reservation.unit?.parking_spot_by_garage,
        driverFullName: reservation.name,
        driverEmail: reservation.email,
        licensePlate: reservation.number_plate,
        phoneNumber1: reservation.phone_number,
        phoneNumber2: reservation.phone_number_2,
        startDate: reservation.begins_at,
        endDate: reservation.ends_at,
        createdAt: reservation.created_at,
        price: (reservation.amount_cents/100)+((reservation.extra_payments_amount?.cents ?? 0)/100),
        token: reservation.token,
        unpaidUrl: reservation.unpaid_url,
        unpaidAmount: {
            cents: reservation.unpaid_amount?.cents,
            currency: reservation.unpaid_amount?.currency_iso,
        },
        ...reservation.unit.entrance?.id ? {
            entrance: {
                id: reservation.unit.entrance.id,
                gateClass: reservation.unit.entrance.class,
                isVisible: reservation.unit.entrance.is_visible_by_user,
                name: {
                    cs: reservation.unit.entrance.name,
                    en: reservation.unit.entrance.name_en,
                    de: reservation.unit.entrance.name_de,
                    pl: reservation.unit.entrance.name_pl,
                },
            }
        } : {},
        ...reservation.unit.exit?.id ? {
            exit: {
                id: reservation.unit.exit.id,
                gateClass: reservation.unit.exit.class,
                isVisible: reservation.unit.exit.is_visible_by_user,
                name: {
                    cs: reservation.unit.exit.name,
                    en: reservation.unit.exit.name_en,
                    de: reservation.unit.exit.name_de,
                    pl: reservation.unit.exit.name_pl,
                },
            }
        } : {},
        ...reservation.unit.garage.reception_doors.length > 0 ? {
            receptions: [...reservation.unit.garage.reception_doors.map(receptionGate => ({
                id: receptionGate.id,
                gateClass: receptionGate.class,
                isVisible: receptionGate.is_visible_by_user,
                name: {
                    cs: receptionGate.name,
                    en: receptionGate.name_en,
                    de: receptionGate.name_de,
                    pl: receptionGate.name_pl,
                },
            }))]
        } : {},
        invoices: [...reservation.invoices],
        tickets: [...reservation.tickets],
        creditNotes: [...reservation.credit_notes],
        invoiceCorrections: [...reservation.invoice_corrections],
        terms_of_services: {
            ...reservation.terms_of_services
        },
        privacy_policy: {
            ...reservation.privacy_policy
        },
        garage_tos: {
            ...reservation.garage_tos
        },
        change_unit_process_actual: reservation.change_unit_process_actual ? {
            id: reservation.change_unit_process_actual.id,
            successful_at: reservation.change_unit_process_actual.successful_at,
            failed_at: reservation.change_unit_process_actual.failed_at,
        } : null,
    })
}
